import React, {Fragment, useEffect, useState} from "react";
import {PersonSection}                        from "./PersonSection";
import PropTypes                              from 'prop-types';
import {Spinner}                              from "../dom/Spinner";
import {ErrorContainer}                       from "../dom/ErrorContainer";
import {ElementsContext}                      from "../enums/ElementsContext";
import {UserSection}                          from "./UserSection";
import {Utils}                                from "../Utils";
import _                                      from "lodash/fp";

const axios = require("axios").default;

export const TeacherForm = props => {
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [element, setElement] = useState(props.element || {});
    const [person, setPerson] = useState(props.element?.person || {});
    const formId = "teacher_edit_form";

    useEffect(() => {
    });

    useEffect(() => {
    }, []);

    const applySubmit = (event) => {
        event.applySubmit = true;
        handleSubmit(event);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let $form = $("#" + formId);
        if (!$form.valid()) {
            return false;
        }
        setLoading(true);

        axios.put('/api/teachers/', {
            element: element,
            backUrl: props.backUrl,
        })
             .then((response) => {
                 if (!Utils.isInteger(response.data)) {
                     setErrors(errors => [...errors, "Не удалось записать данные!"]);
                     setLoading(false);
                 }
                 if (props.backUrl && !event.applySubmit) {
                     window.location.href = props.backUrl;
                 }
             })
             .catch((errors) => {
                 setElement(errors.data.element);
                 setErrors(errors => [...errors, errors.data.errors]);
                 setLoading(false);
             });
    };

    const onPersonChange = (person) => {
        let newPersonData = {
            person: person,
            person_id: person.ID,
            user: person.user,
            user_id: person.user?.ID,
        };
        setElement({...element, ...newPersonData});
        setPerson(person);
    };

    const onUserChange = (user) => {
        let newUserData;
        if (!_.isEmpty(user)) {
            newUserData = {
                user: user,
                user_id: user.ID,
            };
        } else {
            newUserData = {
                user: null,
                user_id: null,
            };
        }
        setElement({...element, ...newUserData});
    };

    const SuccessMessage = props => (
        <div className={"alert alert-success"}>
            Педагог успешно записан!
        </div>
    );

    if (isLoading) {
        return <Spinner/>;
    }

    return (
        <Fragment>
            <PersonSection
                context={ElementsContext.TEACHER}
                onPersonChange={onPersonChange}
                onPersonSet={(person) => setPerson(person)}
                personId={element.person_id || ""}/>

            {person?.ID ?
                <form id={formId} onSubmit={handleSubmit} method={"POST"}>

                    {success ? <SuccessMessage/> : ""}
                    {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}

                    <div className={"row mb-2"}>
                        <div className={"col-12"}>
                            <div className="card mb-3 shadow-sm rounded-lg p-3">
                                <UserSection
                                    user={element.user}
                                    person={person || {}}
                                    edit={false}
                                    showHeader={true}
                                    context={ElementsContext.TEACHER}
                                    onUserChange={onUserChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="text-end">
                        <button className="btn btn-primary me-2"
                                name="submit"
                                onClick={handleSubmit}
                                value="Y">
                            Сохранить и закрыть
                        </button>
                        <button className="btn btn-outline-secondary me-2"
                                name="apply"
                                onClick={applySubmit}
                                value="Y">
                            Записать
                        </button>
                        <a href={props.backUrl} className="btn btn-secondary btn-cancel">отменить</a>
                    </div>

                </form>
                :
                ""
            }
        </Fragment>
    );

};

TeacherForm.propTypes = {
    formAction: PropTypes.string,
    backUrl: PropTypes.string,
    element: PropTypes.object,
    departments: PropTypes.array,
};

