import {Pages}                from "./Pages";
import React                  from "react";
import ReactDOM               from "react-dom";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import "jquery-validation/dist/localization/messages_ru";
import {TeacherForm} from "../components/TeacherForm";

export class TeachersDetail extends Pages {

    constructor(params) {
        super(params);

        this.data = window.diaryJsData;
        ReactDOM.render(
            <TeacherForm
                formAction={this.data.formAction}
                backUrl={this.data.backUrl}
                element={this.data.element}
                departments={this.data.departments}/>,
            document.getElementById('teacherForm')
        );

    }

    initPageHandlers(params) {
        super.initPageHandlers(params);
    }
}
